import * as React from "react";
import Layout from "../components/Layout";
import mailchimp_logo from "../img/external-logos/mailchimp_logo.svg";
import litmus_logo from "../img/external-logos/litmus_logo.svg";
import explainer_thumb from "../img/home-page/ExplainerThumbnail.png";
import { Link } from "gatsby";
import '../../node_modules/react-modal-video/scss/modal-video.scss';
import ModalVideo from 'react-modal-video'
import { CheckCircleIcon } from "@heroicons/react/outline";
import queryString from 'query-string';
import EmailListSignup from '../components/EmailListSignupDemo';
import SubSenseCollectionScript from "../components/SubSenseCollectionScript";


const HomePage = ({location}) => {
  const parsed = queryString.parse(location.search);
  
  const [isOpen, setOpen] = React.useState(parsed.video === 'open')

  return (<Layout footer_children={<SubSenseCollectionScript/>}>
    <div className="">
      <div className="sm:text-center md:max-w-4xl md:mx-auto lg:col-span-6 lg:text-left mb-10">
        <div className="my-3">
          <span className="mt-1 block tracking-tight font-medium">
            <h1 className="block text-4xl sm:text-5xl xl:text-6xl my-0">
              <span>Unfortunately, Subsccribe Sense is being sunsetted.</span>
            </h1>
          </span>
          <h2 className="mt-1 block text-2xl tracking-tight font-medium sm:text-3xl xl:text-4xl py-12">
            <span className="block">
              <span className="text-grey-900">Thank you for your interest, and best wishes on growing your mailing list! </span><br/>
            </span>
          </h2>
        </div>
      </div>
    </div>

  </Layout>)
};

export default HomePage;